@charset "utf-8"; /************************************************
Version : 1.0
Date : 2023-03-11
00. WEBFONT
01. reset
02. 기본설정
03. common 요소
04. popup
05. layout
06. 개별 페이지

*************************************************/

@import url(react-slider.css);

@font-face {
  font-family:'Noto Sans KR';
  font-style:normal;
  font-weight:400;
  src:url(../fonts/NotoSansKR-Regular.woff2) format('woff2'),
  url(../fonts/NotoSansKR-Regular.woff) format('woff'),
  url(../fonts/NotoSansKR-Regular.otf) format('opentype');
}
@font-face {
  font-family:'Noto Sans KR';
  font-style:normal;
  font-weight:500;
  src:url(../fonts/NotoSansKR-Medium.woff2) format('woff2'),
  url(../fonts/NotoSansKR-Medium.woff) format('woff'),
  url(../fonts/NotoSansKR-Medium.otf) format('opentype');
}
@font-face {
  font-family:'Noto Sans KR';
  font-style:normal;
  font-weight:700;
  src:url(../fonts/NotoSansKR-Bold.woff2) format('woff2'),
  url(../fonts/NotoSansKR-Bold.woff) format('woff'),
  url(../fonts/NotoSansKR-Bold.otf) format('opentype');
}



.test-container {height: 100%; display: flex; flex-direction: column;}
.test-header {font-family: Verdana, Geneva, Tahoma, sans-serif; font-size: 13px; margin-bottom: 0.5rem;}
.test-header .example-section {margin-bottom: 0.5rem;}
#myGrid {flex: 1 1 0px;}

/* --- 01. reset --- */
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video {margin: 0; padding: 0; border: 0; box-sizing: border-box;}
article,aside,details,dt,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {display: block}
audio,canvas,progress,video {display: inline-block; vertical-align: baseline}
blockquote,q {quotes: none;}
blockquote:after,blockquote:before,q:after,q:before {content: ''; content: none;}
hr {display: none;}
ol,ul {list-style: none;}
table {border-spacing: 0; border-collapse: collapse;}
abbr[title] {border-bottom: 1px dotted}
mark {background: #ff0; color: #000}
sub,sup {position: relative; vertical-align: baseline; line-height: 0;}
sup {top: -.5em}
sub {bottom: -.25em}
svg:not(:root) {overflow: hidden}
pre,textarea {overflow: auto}
i {font-style: normal;}
/* --- 02. 기본설정 --- */
* {box-sizing: border-box; -webkit-tap-highlight-color: rgba(0, 0, 0, .1);}
::-webkit-input-placeholder {font-size: inherit; color: #999;}
::-moz-placeholder {font-size: inherit; opacity: 1;}
html {height: 100%; font-size: 10px; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;}
/*body {height: 100%; font-size: 1.2rem; font-family:'Noto Sans KR','AppleSDGothicNeo','roboto',Sans-Serif; font-weight: 400; color: #000; background-color: #Ffff;}*/
/* body * {font-size: 1.5rem;}  */
strong {font-weight: 500;}
em,b {font-style: normal}
a {cursor: pointer; background-color: transparent; color: inherit;}
a,label,span {display: inline-block;}
a,a:hover {text-decoration: none;}
a:hover,a:focus,a:hover {outline: 0;}
button {border: 0; cursor: pointer; background: none; font-family:'Noto Sans KR','AppleSDGothicNeo','roboto',Sans-Serif;}
input[type="text"],input[type="tel"],input[type="password"] {height: 4rem; padding-left: 1.4rem; font-size: 1.6rem; border-radius: .2rem; border: 1px solid #999; background: #FFF; font-family:'Noto Sans KR','AppleSDGothicNeo','roboto',Sans-Serif;}
select {width: 100%; height: 4rem; padding-left: 1.4rem; border: 1px solid #999; border-radius: .3rem; font-size: 1.5rem; color: #6F6F6F; background: #fff url("../../../public/images/select_arrow-up.png") no-repeat right -.1rem top 50%/4rem auto; -webkit-appearance: none; -moz-appearance: none; appearance: none;font-family: 'Noto Sans KR', 'AppleSDGothicNeo', 'roboto', Sans-Serif;}
textarea {padding: 1rem; width: 100%; border: 1px solid #999;}
.hidden {border: 0; clip: rect(0 0 0 0); clip-path: inset(50%); width: 1px; height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; white-space: nowrap;}
.required {color: #FF0000;}
input.right {text-align: right; padding-right: 1rem; color: #000;}

/* --- 03. common 요소 --- */
/*scrollbar*/
::-webkit-input-placeholder {font-size: inherit; color: #888;}
::-moz-placeholder {font-size: inherit; opacity: 1;}
::-webkit-scrollbar {width: .2rem; height: .2rem; display: yes;}
::-webkit-scrollbar-thumb {background-color: #D9D9D9; border: 0; border-radius: .5rem;}
::-webkit-scrollbar-track {background-color: transparent; box-shadow: none;}
::-webkit-scrollbar-button {width: 0; height: 0; border: 1px solid #ccc;}

/*heading*/
.h2-title {font-size: 1.8rem; font-weight: 700; }
.h3-title {font-size: 1.5rem; font-weight: 500;}

.title-wrap {display: flex; justify-content: space-between; align-items: center; margin-top: 1.3rem; margin-bottom: .8rem; height: 2.6rem;}
.title-wrap .h2-title {font-size: 1.8rem;}
.title-wrap p {font-size: 1.2rem;}

/*paragraph*/
.info-txt {padding-left: 3rem;; font-size: 1.8rem; line-height: 2.2rem;}
.type-underline {text-decoration: underline;color: #3478F5; padding: 0;}

/*form*/
input[type=checkbox] {width: 2.5rem; height: 2.5rem;}
input[type=radio] {width: 2.5rem; height:2.5rem;}
label {display: flex; align-items: center; font-size: 1.8rem; font-weight: 700;}

input[type=text]:disabled,
input[type=password]:disabled,
input[type=button]:disabled,
select:disabled {opacity: 0.3;}

.disabled {opacity: 0.8; color: #ccc;}
input[type=checkbox]:disabled,
input[type=radio]:disabled {opacity: 0.8;}

.radio-item {display: flex; align-items: center; flex-wrap: wrap; font-size: 1.8rem; font-weight: 700;}
.radio-item label {display: inline-flex;}
.radio-item label:not(:last-child)  {margin-right: 2rem;}

.check-item {display: flex; align-items: center; flex-wrap: wrap; font-size: 1.8rem; font-weight: 700;}
.check-item label {display: inline-flex;}
.check-item label:not(:last-child)  {margin-right: 2rem;}

.custom-checkbox {display: flex; align-items: center;}


/* input[readonly] {color: #adadad !important;}
input[disabled] {background: #f9f9f9;}
input[type=checkbox],input[type=radio] {opacity: 0;  position: absolute; left: 0; cursor: pointer;}
input[type=checkbox]+label,input,input[type=radio]+label,input[type=radio]:focus+label {outline: 0;}
input[type=radio]:checked:disabled+label:after,input[type=radio]:disabled+label:after,input[type=checkbox]:checked:disabled+label:after,input[type=checkbox]:disabled+label:after {opacity: .5;}
input[type=checkbox]+label {display: flex; align-items: center; height: 1.6rem; cursor: pointer; position: relative; color: #474747; font-size: 1.5rem;}
input[type=checkbox]+label:after {content: ''; width: 1.6rem; height: 1.6rem; position: absolute;top: 50%; margin-top: -.8rem; left: 0; background: url('../../../public/images/check-off.png') no-repeat 50% 50%/1.6rem 1.6rem;}
input[type=checkbox]:checked+label::before {content: ''; width: 1.6rem; height: 1.6rem; position: absolute; top: 50%; margin-top: -.8rem;  left: 0; z-index: 2; background: url('../../../public/images/check-on.png') no-repeat 50% 50%/1.6rem 1.6rem;}
input[type=radio]+label {display: flex; align-items: center; height: 2.6rem; padding: 0 0 0 3.2rem; cursor: pointer; position: relative; color: #666; font-size: 1.5rem;}
input[type=radio]+label:after {content: ''; width: 1.6rem; height: 1.6rem; position: absolute; top: 50%; margin-top: -.8rem; left: 0; border: 1px solid #999; background: #fff; border-radius: 100%;}
input[type=radio]:checked+label::before {content: ''; position: absolute; top: 50%; margin-top: -.8rem; left:0; z-index: 2; width: 1.2rem; height: 1.2rem; background: #000; border-radius: 100%;}   */

/* .custom-checkbox {display: block; position: relative; padding-left: 2rem; cursor: pointer; font-size: 1.5rem;margin-bottom: 10px;}
.custom-checkbox::after {content: ""; position: absolute; left: 0; top: .2rem; width: 1.6rem; height: 1.6rem; background: url('../../../public/images/check-off.png') no-repeat 50% 50%/1.6rem 1.6rem;}
.custom-checkbox input[type="checkbox"]:checked + ::after {border: 1px solid red; background: url('../../../public/images/check-on.png') no-repeat 50% 50%/1.6rem 1.6rem;} */


.search-item {display: block; height: 4rem; position: relative; width: 100%; height: 4rem; box-sizing: border-box; border: 1px solid #999; border-radius: .3rem; font-size: 1.5rem; color: #6F6F6F; overflow: hidden; background: #fff;}
.search-item input {border: 0; width: calc(100% - 50px); height: 3.8rem; padding: 0; text-indent: 1rem;}
/*08.30*/
.search-item.disabled {opacity: 0.3;}

.search-item .icon-btn-search {position: absolute; right: 0; height: 3.8rem; width: 3.8rem; background: #E7E7E7 url("../../../public/images/btn-search.png") no-repeat 50% 50%/1.1rem auto;}

/*button*/
.btn-area {display:flex; width:100%; margin-top: auto;}
.btn-area.bottom {padding:2rem; background: #fff;}
.btn-area button+button {margin-left: 1.2rem;}

.btn-area.num3 button {font-size: 1.6rem; min-width: auto;}
.btn-area.num3 button + button {margin-left: .4rem;}

[class*="button-"] {height: 4.9rem;  width:100%; padding: 0 2rem; border-radius: 3.6rem; font-size: 1.8rem; font-weight: 700;}
[class*="button-"].sm {height: 3.2rem; width: auto;}
[class*="button-"].square {border-radius: 0;}
[class*="button-primary"]:disabled {background: #ccc; border-color: #D9D9D9; }
[class*="button-primary"] {background-color: #3478F5; color: #fff; white-space: nowrap;}
[class*="button-primary"]:active,
[class*="button-primary"].active[class*="button-primary"].active {background-color: #1E4FA8;}
[class*="button-secondary"] {border: 1px solid #E1E1E1; background: #E1E1E1; white-space: nowrap;}
[class*="button-secondary"]:active,
[class*="button-secondary"].active {background-color: #D1D1D1;}
[class*="button-secondary"]:disabled {background: #ccc; border-color: #ccc; }
[class*="button-line"] {background-color: #fff; border: 1px solid #3478F5; color: #3478F5; white-space: nowrap;}
[class*="button-line"]:active,
[class*="button-line"].active {border: 1px solid #E6E6E6; background: #E6E6E6;}
[class*="button-line"]:disabled {background: #E6E6E6;; border-color: #999; color: #999;}
[class*="icon-button-"] {min-width: auto; padding: 0; border-radius: 0;}
[class*="icon-button-"] i {font-size: 0; opacity: 0;;}
/*.pop-layer [class*="button-"] {height: 3.6rem;  font-size: 1.6rem; }*/
.btn-gallary {width: 2.9rem; height: 2.9rem; background: url("../../../public/images/icon-gallary.png") no-repeat 50% 50%/2.9rem auto;}
.btn-search2 {width: 2.9rem; height: 2.9rem; background: url("../../../public/images/btn-search2.png") no-repeat 50% 50%/2.9rem auto;}
.btn-search2:active {background-image: url("../../../public/images/btn-search2-on.png");}
.btn-favorite { width: 3rem; height: 3rem; background: #fff url("../../../public/images/icon-favorite-off.png") no-repeat 50% 50%/3rem auto;}
.btn-barcode {width: 2rem; height: 3rem; margin-right: 1rem; background: url("../../../public/images/btn-barcode.png") no-repeat 0 50%/1.8rem auto;}
.btn-ocr {width: 2rem; height: 3rem; margin-right: 1rem; background: url("../../../public/images/ocr.png") no-repeat 0 50%/1.8rem auto;}
.btn-favorite.on { background: #fff url("../../../public/images/icon-favorite-on.png") no-repeat 50% 50%/3rem auto;}
.button-cancel {width: 3.2rem; height: 3.2rem; min-width: auto; padding: 0; border-radius: 3.6rem; font-size: 0; background:#E1E1E1 url("../../../public/images/btn-cancel.png") no-repeat 50% 50%/2.2rem auto;}
.button-delete {width: 3.2rem; height: 3.2rem; min-width: auto; padding: 0; border-radius: 3.6rem; font-size: 0; background:#E1E1E1 url("../../../public/images/btn-cancel.png") no-repeat 50% 50%/2.2rem auto;}

.button-shoot {width: 4.7rem; height: 4.7rem; background: url("../../../public/images/button-shoot.png") no-repeat 50% 50%/4.7rem auto;}
.button-shoot:active {background-image: url("../../../public/images/button-shoot-on.png");}

/*datepicker*/
.datepicker-wrap {display: flex; align-items: center;}
.datepicker-wrap .date-item {position: relative; min-width: 22.2rem; height: 4rem; box-sizing: border-box; border: 1px solid #999; border-radius: .3rem; font-size: 1.5rem; color: #6F6F6F; overflow: hidden; background: #fff;}
.datepicker-wrap .date-item input {border: 0; width: calc(100% - 50px); height: 3.8rem; padding: 0; text-indent: 1rem;}
.datepicker-wrap .date-item .icon-btn-date {position: absolute; right: 0; height: 3.8rem; width: 3.8rem; background: #E1E1E1 url("../../../public/images/icon-calendar.png") no-repeat 50% 50%/2.25rem auto;}
.datepicker-wrap .date-item .icon-btn-date:active {background: #D1D1D1;}
.datepicker-wrap i {width: 2.5rem; text-align: center;}
.datepicker-wrap.type2 .date-item {min-width: 16.2rem;}
.datepicker-wrap.disabled .date-item .icon-btn-date {opacity: .3;}
/*table*/
.table-wrap {position: relative; margin: 0; padding: 0 2rem; border-bottom: 0; background: #fff; overflow-y: auto;}
.table-wrap table {width: 100%; table-layout: fixed;}
.table-wrap table thead {position: sticky; top: 0; left: 0; width: 100%;}
.table-wrap table th {height: 4.2rem; padding: 0 .5rem; background: #E6E6E6; vertical-align: middle; font-size: 1.2rem; font-weight: 400; border-bottom: 1px dashed #B8B8B8;}
.table-wrap table td {height: 4.2rem; padding: .2rem .5rem; vertical-align: middle; font-size: 1.2rem; font-weight: 400; border-bottom: 1px dashed #B8B8B8; text-align: center;}
.table-wrap table td.left {text-align: left; padding-right: 0;}
.table-wrap table td.right {text-align: right;}
/* .table-wrap table td a {display: block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}  */
.table-wrap table tr td:last-child {padding-right: 0;}
.table-wrap table tr.bg-color-y td:not(:last-child) {background: #FFF5BF;}
.table-wrap table tr.bg-color-p td:not(:last-child) {background: #FED8FF;}

/*결과상태*/
.label-state {width: 3.4rem; height: 2.3rem; display: inline-flex; justify-content: center; align-items: center; border-radius: .7rem;  font-size: 1.2rem; font-weight: 900; color: #fff;}
.label-state.ok {background: #3478F5;}
.label-state.wait {background: #BBB;}
.label-state.ng {background: #FF005C}

/*tab*/
.tabs-wrap {position: relative; width: 100%; margin-bottom: 1.5rem;}
.tabs-wrap:after {content: ""; position: absolute; bottom: .1rem; left: 0; width: 100%; border-bottom: 1px solid #525252;}
.tabs-wrap .tab-list {display: flex; align-items: center; justify-content: flex-start; overflow-x: scroll; padding-left: 2rem; }
.tabs-wrap .tab-list li:not(:last-child) {margin-right: .2rem;}
.tabs-wrap .tab-list li button {border-radius: 1.5rem 1.5rem 0 0; width: 10.8rem;  height: 4.2rem; font-size: 1.4rem; color: #6C6C6C; background-color: #E6E6E6;}
.tabs-wrap .tab-list li.on button {color: #fff; font-weight: 700; background-color: #525252;}
.tabs-wrap ::-webkit-scrollbar-thumb {background-color: transparent;}

.tabs-wrap.type2 {position: relative; width: 100%; margin-bottom: -.2rem;}
.tabs-wrap.type2:after {content: ""; position: absolute; bottom: .2rem; left: 0; width: 100%; z-index: 1; border-bottom: 1px solid #B8B8B8;}
.tabs-wrap.type2 .tab-list {display: flex; align-items: center; justify-content: flex-start; overflow-x: scroll; padding-left: 0; position: relative; z-index: 2;}
.tabs-wrap.type2 .tab-list li:not(:last-child) {margin-right: .2rem;}
.tabs-wrap.type2 .tab-list li button {border-radius: 1.5rem 1.5rem 0 0; width: 8.7rem;  height: 3rem; border-bottom: .3rem solid #fff; font-size: 1.4rem; color: #858585; background-color: #E6E6E6;}
.tabs-wrap.type2 .tab-list li.on button { height: 3.2rem; color: #000; background-color: #fff; border: 1px solid #858585; border-bottom: 1px solid #fff;}


.tab-content {display: none; position: relative;}
.tab-content:after {content: ""; clear: both; display: block;}
.tab-content.current {display: block;}

/* ---04. popup --- */
.pop-layer {display: none; position: fixed; left: 0; top: 0; bottom: 0; height: 100%; right: 0; width: 100%; background-color: rgba(165, 165, 165, 0.50); z-index: 999;}
.pop-layer .popup-wrap {display: flex; flex-direction: column; position: absolute; top: 50%; left: 50%; width: 80%; transform: translate(-50%, -50%); background: #fff; box-shadow: 0px 11px 15px  rgba(0, 0, 0, 0.40) }
.pop-layer .popup-wrap .pop-tit { display: flex; align-items: center; justify-content: space-between; padding:3rem 2rem 1rem;}
.pop-layer .popup-wrap .pop-tit em {font-size: 1.8rem; font-weight: 600;}
.pop-layer .popup-wrap .pop-tit .btn-close {position: static; width: 3.2rem; height: 3.2rem; min-width: auto; padding: 0; border-radius: 3.6rem; font-size: 0; background:#E1E1E1 url("../../../public/images/btn-cancel.png") no-repeat 50% 50%/2.2rem auto;}
.pop-layer .popup-wrap .btn-close {display: block; overflow: hidden; position: absolute; right: 2.5rem; top: 0; height: 5rem; min-width: auto; width: 4rem; z-index: 3; text-indent: -999px; background: url('../../../public/images/btn-close.png') no-repeat 50% 50%;}
.pop-layer .popup-wrap .pop-conts {padding: 2rem}
.pop-layer .popup-wrap .pop-conts .align-left {display: inline-flex; flex-direction: column; text-align: left;}
.pop-layer .popup-wrap .pop-conts .title{margin-bottom: .5rem; font-size: 2rem; font-weight: 500; text-align: left;}
.pop-layer .popup-wrap .pop-conts .session-title {margin-bottom: .5rem; padding-left: 2.4rem; font-size: 2rem; font-weight: 500; text-align: left; background: url("../../../public/images/icon-error.png") no-repeat 0 50%/1.7rem auto;}
.pop-layer .popup-wrap .pop-conts p {color: #6C6C6C; font-size: 1.2rem;}
.pop-layer .popup-wrap .btn-area.popup {padding:0 2rem 2rem; justify-content: center;}
.pop-layer .popup-wrap .btn-area.popup button {width: auto; min-width: 7.2rem;}
.pop-layer .wrap {background-color: #ffff;}
/*bottom type*/
.pop-layer .popup-wrap.bottom-type {top: initial; bottom:0; left: 0; width: 100%; max-height: 50%; transform: none; border-radius: 30px 30px 0px 0px;}
.pop-layer .popup-wrap.bottom-type .btn-area {padding: 0 2rem 2rem;}
.pop-layer .popup-wrap.bottom-type .pop-conts { padding: 2rem; height: 100%; overflow: auto;}

/* popup 가로 크기*/
.pop-layer .popup-wrap.sm {width: 53.4rem;}
.pop-layer .popup-wrap.md {width: 72rem;}
.pop-layer .popup-wrap.lg {width: 83rem;}
.pop-layer .popup-wrap.xlg {width: 132rem;}

/* dialog-*/
.pop-layer .popup-wrap.dialog {max-width: 28rem;}
.pop-layer .popup-wrap.dialog .pop-conts {text-align: center; margin-bottom: 1rem;}

.pop-layer .popup-wrap.dialog .pop-conts p + p {margin-top: 1.5rem;}

/* --- 05. layout --- */
.wrap {display: flex; flex-direction: column; width: 100%; height: 100%;min-height: 67rem;}
/*header {flex-shrink: 0; z-index: 3; position: sticky; top: 0; left:0; width: 100%; height: 5.7rem;border-bottom: 1px dotted #ccc; background-color: #fff;}*/
header h1 {flex: 1; display:flex; align-items:center; justify-content: center; height: 5.7rem; font-size:2rem; font-weight:700;}
header .btn-wrap {position: absolute; top:.5rem;}
header .btn-wrap.left {left: 0;}
header .btn-wrap.right {right: 0;}
header .button-back {width:4.8rem; height:4.8rem; background:url("../../../public/images/btn-back.png") no-repeat 50% 50%/2.5rem auto;}
header .button-menu {width:4.8rem; height:4.8rem; background:url("../../../public/images/btn-menu.png") no-repeat 50% 50%/2.5rem auto;}
header .button-close {width:4.8rem; height:4.8rem; background:url("../../../public/images/btn-close.png") no-repeat 50% 50%/2.2rem auto;}
header.main {border-bottom: 0; display: flex; align-items: center;}
header.main .select-item { padding-left: 2rem;}
header.main .select-item select {min-width: 12rem; height: 28px; background: #fff url("../../../public/images/select_arrow-up2.png") no-repeat right .5rem top 50%/2.2rem auto;}

.all-menu-wrap {display: none; position: fixed; top: 0; left: 0; z-index: 100; width: 100%; height: 100%; background-color: #fff;}
.all-menu-wrap .inner {width: 100%; height: 100%; display: flex; flex-direction: column;}
.all-menu-wrap .all-menu-top {display: flex; align-items: center; justify-content: space-between; width: 100%; height: 5.7rem; padding: 0 1.8rem; border-bottom: 1px dotted #ccc;}
.all-menu-wrap .all-menu-top .left button {height: 3rem; padding-left: 2.5rem; font-size: 1.8rem; font-weight: 700; margin-right: 1rem;}
.all-menu-wrap .all-menu-top .btn-home {background:url("../../../public/images/menu-home.png") no-repeat 0 50%/2.4rem auto;}
.all-menu-wrap .all-menu-top .btn-logout {background:url("../../../public/images/menu-logout.png") no-repeat 0 50%/2.4rem auto;}
.all-menu-wrap .all-menu-top .btn-setting {background:url("../../../public/images/btn-set.png") no-repeat 0 50%/2.4rem auto;}
.all-menu-wrap .all-menu-top .btn-close {width: 3rem; height: 3rem;background:url("../../../public/images/btn-close.png") no-repeat 50% 50%/2.4rem auto;}
.all-menu-wrap .menu-wrap {height: calc(100% - 5.7rem); overflow-y: auto;}
.all-menu-wrap .menu-wrap .title {border-bottom: 1px solid #3478F5; margin-top: 1rem;}
.all-menu-wrap .menu-wrap .title strong {display: block; width: 9.5rem; text-align: center; padding-bottom: .5rem; line-height: 3.5rem; font-size: 2.4rem; font-weight: 700; border-bottom: 4px solid #3478F5;}
.all-menu-wrap .menu-wrap article {padding: 1.5rem 0; margin-left: 7.2rem;}
.all-menu-wrap .menu-wrap article h2 {margin-bottom: 1.5rem;}
.all-menu-wrap .menu-wrap article .menu-list {display: block; margin: 0 0 0 -.5rem;}
.all-menu-wrap .menu-wrap article .menu-list li {display: block; width: 100%; height: 5rem; margin-bottom: .8rem;}
.all-menu-wrap .menu-wrap article .menu-list button {font-size: 1.6rem;}
.all-menu-wrap .menu-wrap article .menu-list button br {display: none;}
.all-menu-wrap .menu-wrap article .menu-list button i {width: 5rem; height: 5rem; margin-right: 1rem;}
.all-menu-wrap .menu-wrap article .menu-list button {flex-direction: initial;}

/*메인/전체메뉴 공통 사용*/
.menu-wrap article {padding: 2rem 2rem 2rem; border-bottom: 1px dashed #999;}
.menu-wrap article:last-child {border-bottom: 0;}
.menu-wrap article h2 {font-size: 1.8rem; font-weight: 700; margin-bottom: 1rem;}
.menu-wrap article .menu-list  {display: flex; margin: 0 -1.5rem;}
.menu-wrap article .menu-list li {display: flex; justify-content: center; width: calc(100%/3); text-align: center;}
.menu-wrap article .menu-list button {display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: 1.4rem;}
.menu-wrap article .menu-list button i {width: 8.1rem; margin-right: .5rem;}
.menu-wrap article .menu-list button i img {width: 100%;}

/*container*/
/* .container {position: relative;display: flex; flex-direction: column; width: 100%; padding-top: 1.5rem; height: calc(100% - 5.7rem);}  */
.container {position: relative;display: flex; flex-direction: column; width: 100%; padding-top: 1.5rem; height: 100% !important;}
.transitions-wrapper {display: flex; flex-direction: column; }

.container.login .login-conts fieldset input[type=submit]{width:100%; height: 5rem; border-radius: 3.6rem; border: 1px solid #3478F5; background: #3478F5; color: #FFF; text-align: center; font-size: 1.8rem; font-weight: 700;}

/*contents-wrap*/
.contents-wrap {overflow-y: visible;  display: flex; flex-direction: column; position: relative; height: 100%; padding: 1rem 2rem 0;}

/*scroll-wrap*/
.scroll-wrap {overflow-y: auto;  display: flex; flex-direction: column; position: relative; height: 100%; padding: 2.5rem 0 0; margin-top: -2.5rem;}

/*footer*/
.container .footer {margin-top: auto; padding:2rem 0;}
.container .footer p {font-size: 1.2rem; text-align: center;}
.container .footer p a {text-decoration: underline; margin-left: .5rem;}
/* --- 06. 개별 페이지 --- */
/*메인*/
.container.main {padding: 0; height: calc(100% - 5.7rem);}
.container.main .main-conts .heading-group {display: flex; align-items: center; justify-content: center; margin-top: 2.7rem;}
.container.main .main-conts .heading-group h1 {margin-right: 1rem; color: #000; font-size: 4rem; font-weight: 700;}
.container.main .main-conts .heading-group p {color: #6C6C6C; font-size: 1.4rem}
/* .container.main .main-conts {flex: 1; overflow-y: auto;} */

/*세팅*/
.setting-list-wrap {padding: 2rem; height: 100%; overflow-y: auto;}
.setting-list-wrap .h2-title {margin-bottom: 3.2rem; }
.setting-list {padding: 0 2rem; margin: 0 -2rem;}
.setting-list li:not(:last-child) {margin-bottom: 3.9rem;}
.setting-list li label{display: flex; align-items: center; justify-content: space-between; font-size: 1.8rem; font-weight: 700;}

/*08.30 수정(srart)*/
.qcr-switch-wrap {border-top: 1px dashed #999; padding: 2rem 0 0; margin-top: 2.5rem; display: flex;justify-content: space-between; position: relative;}
.qcr-switch-wrap .h2-title { }
.qcr-switch-wrap input {position: absolute; top: 0; left: 0; opacity: 0; width: 0; height: 0;}
.qcr-switch-wrap .button-switch {width: 7.5rem; height: 3rem; background:  url("../../../public/images/btn-switch-off.png") no-repeat 50% 50%/ 7.5rem auto; transition: all .2s ease-in;}
.qcr-switch-wrap input:checked + .button-switch {background-image: url("../../../public/images/btn-switch-on.png");}

.qcr-switch-wrap .button-switch.on {background-image: url("../../../public/images/btn-switch-on.png");}
/*08.30 수정(end)*/

/*로그인/비밀번호 재설정*/
.gray-box-wrap {flex-shrink: 0; width: 36.6rem; margin-right: 1rem; padding: 1.5rem 3.9rem; background-color: #F4F4F4; border-radius: .8rem;}
.container.login {display:flex; flex-direction: column; height: 100%; padding-top: 0;}
.container.login .login-header {padding: 1.5rem 2rem 0;}
.container.login .login-header select {width: 120px; height: 28px; background: #fff url("../../../public/images/select_arrow-up2.png") no-repeat right .5rem top 50%/2.2rem auto;}
.container.login .login-conts {padding: 0 2rem; margin-top: 4.7rem;height: 100%; display: flex; flex-direction: column;}
.container.login .login-conts .heading-group {display:flex; flex-direction: column; align-items: center; margin-bottom: 6.4rem;}
.container.login .login-conts .heading-group h1 {color: #000; font-size: 4rem; font-weight: 700;}
.container.login .login-conts .heading-group p {color: #6C6C6C; font-size: 1.6rem}
.container.login .login-conts .heading-group.type2 {display: flex; flex-direction: row; justify-content: center; margin-bottom: 4.9rem;}
.container.login .login-conts .heading-group.type2 h1{margin-right: 1rem;}
.container.login .login-conts .heading-group.type2 p{font-size:1.4rem;}
.container.login .login-conts fieldset input[type=text],
.container.login .login-conts fieldset input[type=password]{width:100%; margin-bottom: 1.5rem;}
.container.login .login-conts fieldset input[type=button]{width:100%; height: 5rem; border-radius: 3.6rem; border: 1px solid #3478F5; background: #3478F5; color: #FFF; text-align: center; font-size: 1.8rem; font-weight: 700;}
.container.login .login-conts fieldset input[type=button]:active {background: #1E4FA8;}
.container.login .login-conts fieldset .divide-wrap {display: flex; justify-content: space-between; align-items: center; margin-bottom: 3.4rem;}
.container.login .login-conts fieldset .divide-wrap label {font-size: 1.4rem;}
.container.login .login-conts fieldset .divide-wrap a {font-size: 1.4rem; color: #3478F5;}

.container.login .login-conts fieldset.error-wrap {border-bottom: 1px solid #B8B8B8; padding-bottom: 2rem; padding-top: 6rem;}
.container.login .login-conts fieldset .error-text {margin: 1.5rem 0 6rem; text-align: center; color: #3478F5; font-size: 1.4rem; line-height: 2.6rem;}
.container.login .login-conts .info-text {padding: 1.4rem 1.5rem; margin-top: 1.5rem; background: #F4F4F4; font-size: 1.2rem;}
.container.login .login-conts .password-text {font-size: 1.2rem; margin-bottom: 1.5rem;}

/*이용약관*/
.privacy-wrap {height: 100%; padding: 0 2rem;}
.privacy-statement {overflow: auto; margin-top:2rem; height: calc(100% - 4rem); padding:1rem; border-radius: 2px;border: 1px solid #999;background: #FFF;}

/*출하검사 성적서 작성*/
/*.reports-list li {display: flex; height:5rem; margin-bottom: 2.5rem;}*/
.reports-list li:last-child {margin-bottom: 0;}
.reports-list li .title {flex-shrink: 0; width: 9.2rem!important; font-size: 1.7rem; font-weight: 700; line-height:1.4; word-break: break-all; }
.reports-list li .title .required {color: #F00;}
.reports-list li > div {flex: 1; padding-left: .8rem;}
.reports-list li.form-item {align-items: center;}
.reports-list li.form-item input {width: 100%;}
.reports-list li.form-item .date-item {width: 100%; min-width: auto;}
.reports-list li.text-item div {display: flex; justify-content: space-between;font-size: 1.6rem;}
.reports-list li.text-item .btn-link {flex-shrink:0; width: 3.2rem; height: 3.2rem; background: #E1E1E1 url("../../../public/images/icon-arrow.png") no-repeat 50% 50%/2.2rem auto; border-radius: 3.6rem;}
.reports-list li.form-item.barcode .title {font-size: 1.4rem; line-height:1.2;}
.reports-list li.form-item.barcode .barcode-item {position: relative; width: 100%; height: 4rem; border-radius: 0.3rem;font-size: 1.5rem;color: #6F6F6F;overflow: hidden; background: #fff;}
.reports-list li.form-item.barcode .barcode-item .btn-barcode { position: absolute; top: .5rem; right: .5rem;}

.contents-table-wrap {margin: 0; padding: 0 2rem; overflow-y: auto;}
.contents-table-wrap .h2-title:not(:first-of-type) {margin-top: 1.5rem;}
.contents-table-list {border-top: 1px solid #B8B8B8; border-bottom: 1px solid #B8B8B8;}
.contents-table-list > li {display: flex; align-items: stretch; border-bottom: 1px dashed #B8B8B8;}
.contents-table-list > li:last-child {border-bottom: 0;}
.contents-table-list > li .title {flex-shrink: 0; display: flex; align-items: center; justify-content: center;  width: 9.2rem!important; min-height: 5rem; padding: .5rem; word-break: break-all; background: #F0F0F0; font-size: 1.4rem; text-align: center;}
.contents-table-list > li .file-title {flex-shrink: 0; width: 10.6rem; display: flex; align-items: center; justify-content: center;  background: #F0F0F0; font-size: 1.4rem; text-align: right;}
.contents-table-list > li .item-wrap {display: flex; align-items: center;flex-grow: 1;  min-height: 5rem; padding: .5rem .5rem .5rem 1.5rem;font-size: 1.4rem; word-break: break-all;}
.contents-table-list > li .item-wrap .item-btn-wrap {  display: block;}
.contents-table-list > li .item-wrap .item-btn-wrap button {height: 3.7rem; font-size: 1.2rem; width: auto; padding: 0 1.4rem; margin:.5rem 0;}

.contents-table-list.form > li .title {min-width: 11rem; padding: .5rem 1.5rem .5rem .5rem;}
.contents-table-list.form > li .item-wrap {padding: .5rem 0 .5rem 1.5rem; min-height: 4rem;}
.contents-table-list.form > li .item-wrap textarea {height: 11.2rem; resize: none; display: block;}
.contents-table-list.form > li .item-wrap.type2 textarea {height: 6.2rem;}
.contents-table-list.form > li .item-wrap input[type=text],
.contents-table-list.form > li .item-wrap input[type=number],
.contents-table-list.form > li .item-wrap input[type=password] {width: 100%;}
.contents-table-list.form > li .item-wrap .check-wrap {display: block; padding: 1.3rem 0;}
.contents-table-list ~ .h2-title {margin-top: 2rem; margin-bottom: 1.2rem;}
.contents-table-wrap.width-type1 .contents-table-list > li .title {width: 12rem!important;}
.contents-table-wrap.width-type2 .contents-table-list > li .title {width: 13rem!important;}

/*col4개 */
.contents-table-list > li > .col {display: flex; align-items: center; width: 100%; min-height: 3.7rem;}
.contents-table-list > li > .col .title {min-width: 8rem; height: 100%;}
.contents-table-list > li > .col .item-wrap {padding:0 .5rem; justify-content: space-between;}
.contents-table-list > li > .col .item-wrap.right {justify-content: flex-end; padding-right: 1rem;}
.contents-table-list > li > .col .item-wrap select {width: 100%; height: 2.6rem; padding-left: .3rem; font-size: 1.4rem; color: #000; background: #fff url("../../../public/images/select_arrow-up.png") no-repeat right 0 top 50%/auto 2.6rem;}

.file-list {margin: -1.3rem 0 -1.3rem -1.5rem; width: calc(100% + 1.5rem);}
.file-list > li {display: flex; align-items: stretch; border-bottom: 1px dashed #B8B8B8;}
.file-list > li:last-child {border-bottom: 0;}
.file-list > li .file-item {display: flex; justify-content: space-between; width: 100%; padding: 1.3rem .5rem 1.3rem 1.3rem; font-size: 1.4rem;}
.file-list > li .file-item em {padding-left: 3.3rem; background: url("../../../public/images/icon-file.png") no-repeat 0 50%/2.4rem auto;}

/*.card-wrap {height: 100%; padding: 2.5rem 2rem 0; margin-top: -2rem; background: #E9E9E9;}*/
.card-wrap.single {height: auto; flex-shrink: 0;}
.card-list .card-item {position: relative; border-radius: 2rem; border: 1px solid #D7D7D7; background: #FFF; margin-bottom: 2rem;}
.card-list .card-item .date-list {padding:.9rem 1.7rem 1.3rem; border-top: 1px dotted #C9C9C9;}
.card-list .card-item .date-list li {display: flex; justify-content: space-between; font-size: 1.4rem; color: #6C6C6C; margin-bottom: .2rem;}
/*.card-list .card-item .date-list li em {display: flex; align-items: center;}*/
/*.card-list .card-item .date-list li span {display: flex; align-items: center;}*/
.card-list .card-item .text-list {padding:.9rem 1.7rem 1.3rem; border-top: 1px dotted #C9C9C9; display: flex; flex-wrap: wrap;}
.card-list .card-item .text-list li {margin-right: 1.5rem; font-size: 1.4rem; color: #6C6C6C; margin-bottom: .2rem;}

/*변경점 확인 풀 팝업 */
.card-list .card-item p {padding:1.4rem 1.7rem; display: flex; flex-direction: column;}
.card-list .card-item p span {margin-bottom: .5rem; font-size: 1.6rem; display: flex; align-items: center;}
.card-list .card-item p span em {margin-right: .5rem;}
.card-list .card-item p strong {font-size: 1.4rem; font-weight: 700;}
.card-list .card-item p .label-type1 {display: inline-flex; height: 2.1rem; margin-right: .5rem; padding: 0 1rem; align-items: center; justify-content: center; border-radius: 1rem; border: 1px solid #A97648; background: #FFF; color: #A97648; font-size: 1.4rem;}
.card-list .card-item p .label-type2 {display: inline-flex; height: 2.1rem; margin-right: .5rem; padding: 0 1rem; align-items: center; justify-content: center; border-radius: 1rem; border: 1px solid #5200FF; background: #FFF; color: #5200FF; font-size: 1.4rem;}

/*카드형 목록 */
.card-list .card-item section {padding:1.4rem 1.7rem .2rem; display: flex; flex-direction: column;}
.card-list .card-item section span {font-size: 1.6rem; display: flex; align-items: center;}
.card-list .card-item section strong {font-size: 1.4rem; font-weight: 700;}
.card-list .card-item section .title-wrap {display: flex; flex-direction: column; align-items: flex-start; height: auto;}
/*.card-list .card-item section .title-wrap span {font-size: 1.6rem; margin-bottom: .2rem;}*/
.card-list .card-item section .title-wrap span.issue {padding-left: 2.1rem; background: url("../../../public/images/icon-issue.png") no-repeat 0 .4rem/1.7rem auto;}
/*.card-list .card-item section .title-wrap strong {display: flex; align-items: center; font-size: 2.4rem; font-weight: 700; line-height: 120%;}*/
.card-list .card-item .button-delete {position: absolute; top: 1rem; right: 1rem;}

.label-wrap {display: flex;}
.label-wrap em.date {position: absolute; right: 1.8rem; font-size: 1.6rem; color: #6C6C6C;}
.label-wrap [class*="label-"] {display: inline-flex; height: 2.4rem; min-width: 7.4rem; padding: 0 1rem; align-items: center; justify-content: center; white-space: nowrap; border-radius: 1rem; color: #fff; font-size: 1.4rem;}
.label-wrap [class*="label-"]:not(:last-child) {margin-right: .5rem;}
.label-wrap [class*="label-type1"] {border: 1px solid #999; background: #999;}
.label-wrap [class*="label-type2"] {border: 1px solid #0982C7; background: #0982C7;}
.label-wrap [class*="label-type3"] {border: 1px solid #A694DA; background: #A694DA;}
.label-wrap [class*="label-type4"] {border: 1px solid #6CAA76; background: #6CAA76;}
.label-wrap [class*="label-type5"] {border: 1px solid #56A0A5; background: #56A0A5;}
.label-wrap [class*="label-type6"] {border: 1px solid #C8AB81; background: #C8AB81;}
.label-wrap [class*="label-type7"] {border: 1px solid #C75E5E; background:#C75E5E;}
.label-wrap [class*="label-type8"] {border: 1px solid #6C6C6C; background: #fff; color: #6C6C6C;}
.label-wrap [class*="label-type9"] {border: 1px solid #000; background: #000;}
.label-wrap [class*="label-type10"] {border: 1px solid #000; background: #000; color:#4ABEFF;}
.label-wrap [class*="label-type11"] {border: 1px solid #525252; background: #fff; color: #6C6C6C;}
.label-wrap [class*="label-type12"] {border: 1px solid #525252; background: #fff; color: #C75E5E;}
.label-wrap [class*="label-type13"] {border: 1px solid #6CAA76; background: #6CAA76; font-size: 1.2rem; width: 13.4rem;}
.label-wrap [class*="label-type14"] {border: 1px solid #C75E5E; background: #C75E5E; font-size: 1.2rem; width: 13.4rem;}
.label-wrap [class*="label-type15"] {border: 1px solid #6C6C6C; background: #F0F0F0; font-size: 1.2rem; width: 13.4rem; color: #6C6C6C;}
.label-wrap [class*="label-type16"] {border: 1px solid #5200FF; background: #5200FF; color: #fff;}
.label-wrap [class*="label-type17"] {border: 1px solid #00215F; background: #00215F; color: #fff;}

.label-wrap [class*="label-time1"] {padding-left: 2.7rem; border: 1px solid #6D96FF;background: #FFF url("../../../public/images/icon-time1.png") no-repeat 1rem 50%/1.6rem auto; color: #6D96FF;}
.label-wrap [class*="label-time2"] {padding-left: 2.7rem; border: 1px solid #62A161; background: #fff url("../../../public/images/icon-time2.png") no-repeat 1rem 50%/1.6rem auto; color: #62A161;}
.label-wrap [class*="label-time3"] {padding-left: 2.7rem; border: 1px solid #A94848; background: #fff url("../../../public/images/icon-time3.png") no-repeat 1rem 50%/1.6rem auto; color: #A94848;}

.image-wrap {height: 100%; padding: 2rem 2rem 0; background: #E9E9E9; overflow-y: auto; display: flex; flex-direction: column; align-items: center; justify-content: center;}
.image-wrap.align-top {justify-content: flex-start; align-items: flex-start; padding: 1.7rem 0 2rem;}
.image-wrap.align-top p {flex-shrink: 0; margin-bottom: 3rem; text-align: center; padding-left: 2rem;}
.image-wrap .img-item img {width: 100%;}

.result-state {margin-bottom: 1.5rem;}
.result-state .result {display: flex; align-items: center; justify-content: center; height: 5.5rem; background-color: #999; color: #fff; font-size: 2.4rem; font-weight: 700;}
.result-state .result.pass {background-color: #3478F5;}
.result-state .result.fail {background-color: #FF005C;}

/*입고 판정결과*/
.result-state.arrow .result {background-image: url("../../../public/images/iocn-arrow-down2.png"); background-position: right 1.6rem top 50%; background-size: 2.2rem auto; background-repeat: no-repeat;}

.button-upload {width: 9.8rem; height: 3.2rem; border-radius: 2.5rem; border: 1px solid #3478F5; background: #3478F5; font-size: 1.8rem; font-weight: 700; color: #fff;}
.attach-file-list {display: flex; flex-wrap: wrap; margin-top: 2rem;}
.attach-file-list li {width: calc(100%/2); margin-bottom: 2rem; display: flex;}
.attach-file-list li:nth-of-type(even) {justify-content: flex-end; padding-right: 1rem;}
.attach-file-list li .file-item {position: relative; width: 90%; min-width: 13.4rem; min-height: 8.4rem;}
.attach-file-list li .file-item .file-download:active {background-color: #D1D1D1;}
.attach-file-list li .file-item .file-download {display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 1.4rem; height: 100%; width: 100%; border-radius: 2rem; background-color: #F0F0F0;}
.attach-file-list li .file-item .file-download .text {display: flex; flex-direction: column; align-items: flex-end; padding-left: 4.4rem; font-size: 1.4rem; background: url("../../../public/images/bxs-file.png") no-repeat 0 50%/4.4rem auto;}
.attach-file-list li .file-item .file-download .text em {word-break: break-all; text-align: right;  align-items: flex-end;}
.attach-file-list li .file-item .button-delete {position: absolute; top: 0; right: -1rem;}

.add-icon-list li {margin-top: 2.3rem;}
.add-icon-list li button {display: inline-flex; align-items: center; padding-left: 3.5rem; justify-content: flex-start;}
.add-icon-list li .icon-camera {background: url("../../../public/images/icon-camera.png") no-repeat 0 50%/2.9rem auto;}
.add-icon-list li .icon-gallary {background: url("../../../public/images/icon-gallary.png") no-repeat 0 50%/2.9rem auto;}
.add-icon-list li .icon-folder {background: url("../../../public/images/icon-folder.png") no-repeat 0 50%/2.9rem auto;}
.add-icon-list li .icon-camera:active {background: url("../../../public/images/icon-camera-on.png") no-repeat 0 50%/2.9rem auto;}
.add-icon-list li .icon-gallary:active {background: url("../../../public/images/icon-gallary-on.png") no-repeat 0 50%/2.9rem auto;}
.add-icon-list li .icon-folder:active {background: url("../../../public/images/icon-folder-on.png") no-repeat 0 50%/2.9rem auto;}

/*동일 Lot 그룹판정*/
.contents-table-wrap.lot h2 {margin-bottom: 1.2rem;}
.contents-table-wrap.lot .btn-area {margin-top: .5rem;}
.contents-table-wrap.lot .btn-area [class*="button-"] {border-radius: 0;}

.lot-wrap {height: 100%; overflow-y: auto;background: #E9E9E9; }
.lot-wrap .contents-table-wrap {background: #fff; padding-bottom: .5rem;}
.lot-wrap .contents-table-wrap .item-wrap {padding-left: 1rem;}
.lot-wrap .contents-table-wrap .radio-wrap {margin: 0 auto; padding-right: 1rem;}
.lot-wrap .contents-table-wrap .radio-wrap label + label {margin-left: 3rem;}
.lot-list-wrap { padding: .5rem 2rem 0; margin-top: 1rem; background: #E9E9E9;}
.lot-list-wrap .h2-title {margin-bottom: .8rem;}
.lot-list-wrap .card-list .card-item {margin-bottom: 1rem;}
.lot-list-wrap .card-list .card-item .item {padding: 1.4rem 1.7rem; color: #6C6C6C}
.lot-list-wrap .card-list .card-item .item .lot-list-head {display: flex; justify-content: space-between; font-size: 1.4rem;}
.lot-list-wrap .card-list .card-item .item .lot-list-head em {color: #000;}
.lot-list-wrap .lot-list {display: flex; flex-wrap: wrap; margin-top: .5rem;}
.lot-list-wrap .lot-list li {margin-right: 1.5rem; font-size: 1.4rem; color: #6C6C6C; margin-bottom: .2rem;}
.lot-list-wrap .lot-list li:nth-of-type(1),
.lot-list-wrap .lot-list li:nth-of-type(2) {width: 100%;}
.lot-list-wrap .lot-list li:nth-of-type(3) {width: 60%; margin-right: 0;}
.lot-list-wrap .lot-list li:nth-of-type(4) {width: 40%; margin-right: 0; text-align: right;}

/*slide contents*/
.comm-slide-wrap {position: relative; height: 100%; padding: 0 2rem; overflow-y: auto;}
.comm-slide-wrap .contents-table-list {margin-bottom: 2rem;}
.comm-slide-wrap .table-wrap {padding: 0; }
.comm-slide-wrap .table-wrap table th {font-size: 1.4rem;}
.comm-slide-wrap .table-wrap table td .result-right {width: 100%; max-width: 15.2rem; text-align: right; padding-right: 1.6rem; font-size: 1.4rem; }
.comm-slide-wrap .table-wrap table td.pad-type {padding: .5rem 0 ;}
.comm-slide-wrap .table-wrap table td.pad-type .result-right { max-width: 100%; text-align: left; }
.comm-slide-wrap .result-image-wrap img {width: 100%;}

/*.comm-slide-wrap .swiper-pagination {position: relative; display: flex;justify-content: center}*/
.comm-slide-wrap .swiper-horizontal>.swiper-pagination-bullets,
.comm-slide-wrap .swiper-pagination-bullets.swiper-pagination-horizontal {bottom: initial; top: 0;}
/*.comm-slide-wrap .swiper-pagination-bullet {width: 1.7rem; height: 1.7rem; border: 2px solid #ccc; background-color: #fff; opacity: 1; font-size: 0;}*/
.comm-slide-wrap .swiper-pagination-bullet-active {border: 4px solid #3478F5;font-size: 0;}

.comm-slide-wrap .pagination-type2 {margin-top: .4rem; margin-bottom: 1.4rem; text-align: center; font-size: 1.4rem; font-weight: 700;}

/**page45.html, page55.html**/
.comm-slide-wrap.type2 {padding: 0;}
.comm-slide-wrap.type2 .contents-table-list {margin-left:2rem; margin-right: 2rem;}

.add-btn-wrap {margin-top: 2rem;}

/* 목록 상단 검색 공통 */
.search-list-wrap { display: flex; flex-direction: column; position: relative; height: calc(100% - 9rem); padding: .5rem 2rem 0; overflow: hidden;}
.search-wrap {margin-bottom: 2.7rem;}
.search-wrap .icon-btn-search {background: #fff url("../../../public/images/btn-search3.png") no-repeat 50% 50%/2.2rem auto;}

.contents-wrap.reports-type .card-wrap {margin: 2rem -2rem 0; overflow-y: visible;}

/*플랜트 검색*/
.plant-search-list {overflow-y: auto;  height: 100%; padding: 0 2rem; margin: 0 -2rem;}
.plant-search-list li:not(:last-child) {margin-bottom: 3.9rem;}
.plant-search-list li label{display: flex; align-items: center; justify-content: space-between; font-size: 1.8rem; font-weight: 700;}

/*부품코드 검색*/
.code-search-list {overflow-y: auto; height: calc(100% - 7rem);padding: 0 2rem; margin: 0 -2rem;}
.code-search-list li:not(:last-child) {margin-bottom: 3.9rem;}
.code-search-list li {display: flex; align-items: center; justify-content: space-between; font-size: 1.8rem; color: #747474;}
.code-search-list li span strong {color: #000; margin-right: .2rem; font-weight: 700;}
.code-search-list li button {flex-shrink: 0; }

/*파트너 검색*/
.partner-list {overflow-y: auto;  height: 100%; padding: 0 2rem; margin: 0 -2rem;}
.partner-list li:not(:last-child) {margin-bottom: 2rem;}
.partner-list li label{display: flex; align-items: center; font-size: 1.8rem; color: #000;}
.partner-list li label strong {flex-shrink: 0; width: 8rem; font-weight: 700;}
.partner-list li label span {flex-grow: 1;}
.partner-list li label input {flex-shrink: 0;}

.inspection-btn-wrap {display: flex; justify-content: space-between; margin-top: 4rem;}
.inspection-btn-wrap a {display: flex; flex-direction: column; width: calc(100%/2 - .7rem);}
.inspection-btn-wrap a .title {display: flex; align-items: center; justify-content: center; height: 5rem; border-radius: 1rem 1rem 0 0; font-size: 1.8rem; font-weight: 700; color: #fff;}
.inspection-btn-wrap a .text {display: flex; align-items: center; justify-content: center; height: 8.6rem; border-radius: 0 0 1rem 1rem; font-size: 3.6rem; font-weight: 700; color: #000;}
.inspection-btn-wrap a.btn-ins .title {background: #00215F;}
.inspection-btn-wrap a.btn-pro .title {background: #5200FF;}
.inspection-btn-wrap a.btn-ins .text {background: #EEE;}
.inspection-btn-wrap a.btn-pro .text {background: #F1F3E7;}

.padding-box {padding: 1.5rem 2rem;}
.padding-box .table-wrap {padding: 0;}

/*P-SIS 검사결과 입력/조회*/
.photo-wrap {margin: 0 0 2rem;}
.photo-wrap .h2-title {padding: 0 2rem; margin-bottom: .5rem;}
.photo-wrap .btn-shoot-wrap {display: flex; align-items: center; justify-content: space-between; height: 5.2rem; padding: 0 1.6rem 0 2rem; background-color:#E9E9E9;}
.photo-wrap .btn-shoot-wrap .button-shoot {flex-shrink: 0; }
.photo-wrap .btn-shoot-wrap input {border: 0; width: calc(100% - 6rem);}
.photo-wrap .btn-shoot-wrap .text-file {display: flex; width: 100%; padding: 1rem 1.5rem; background-color: #fff;}
.photo-wrap .btn-shoot-wrap .text-file em {flex-grow: 1;}
.photo-wrap .btn-shoot-wrap .text-file span {margin-left: 1rem; flex-shrink: 0;}

.result-box { padding: 0 2rem;}
.result-box .h2-title-wrap {margin-bottom: 2rem;}
.result-box .h2-title-wrap {display: flex; align-items: center; justify-content: space-between;}

.result-box .result-write-list { padding-bottom: 1.5rem }
.result-box .result-write-list li {display: flex; align-items: center; margin-bottom:.4rem; height: 4rem;}
.result-box .result-write-list li .num-item {flex-shrink: 0; margin-right: 1rem; width: 1.1rem; font-size: 1.8rem; font-weight:700;}
.result-box .result-write-list li .inp-item {flex-grow: 1;}
.result-box .result-write-list li .inp-item input[type=text] {width: 100%;}
.result-box .result-write-list li .radio-item { flex-grow: 1;}
.result-box .result-write-list li .label-item {flex-shrink: 0; width:3.4rem; margin-left: 1.3rem;}
.result-box .result-write-list li .btn-item {flex-shrink: 0; width:3.2rem; margin-left: 1.3rem;}

.result-box .result-write-list.radio-type {padding-bottom: 0;}
.result-box .result-write-list.radio-type li { margin-bottom: 1rem;}

.dashed-box  {border-top: 1px dashed #999; padding:1.5rem 2rem 0;  margin-top: 1.5rem;}
.dashed-box.type2 {border-top: 0; padding-top: 0;}
.dashed-box .h2-title {  margin-bottom: 1.5rem;}
.dashed-box .result-file {display: flex;}
.dashed-box .result-file .title {flex-shrink: 0; width: 6rem; font-size: 1.4rem; display: flex;}
.dashed-box .result-file .file-name {flex-grow: 1; display: flex; flex-direction: column; padding-right: 1rem;}
.dashed-box .result-file .file-name .upload {width: 9.8rem; margin-top: -.5rem;;}
.dashed-box .result-file .file-name .write {width: 8.2rem; margin-top: -.5rem; background: #999; color: #fff}
.dashed-box .result-file .file-name .complete {width: 11.5rem; margin-top: -.5rem;}
.dashed-box .result-file .file-name span {text-align: right; margin-top: .5rem;}
.result-box .result-file .btn-item {flex-shrink: 0; width:3.2rem; margin-left: 1.3rem;}
.result-box .result-file .button-cancel {width:3.2rem;}

.result-box .barcode-wrap {margin: .5rem;}
.result-box .barcode-item {display: flex; align-items: center;}
.result-box .barcode-item {display: flex; align-items: center;}
.result-box .barcode-wrap .inp-wrap {width: 100%; display: flex; align-items: center; justify-content: space-between;}
.result-box .barcode-wrap .inp-wrap .inp-item { position: relative; width:calc(100% - 5rem);}
.result-box .barcode-wrap .inp-wrap .inp-item .button-cancel {position: absolute; right: .8rem; top:.8rem; width: 2.4rem; height: 2.4rem; background:#E1E1E1 url("../../../public/images/btn-cancel.png") no-repeat 50% 50%/1.6rem auto;}

.result-box .barcode-wrap input {width: 100%;}

.num-btn-list {display: flex; flex-wrap: wrap; margin: 2rem -2rem 0;}
.num-btn-list li {width: calc(100%/3); text-align: center; margin-bottom: 1rem;}
.num-btn-list li .item { width: 6.7rem; height: 3.2rem; background: url("../../../public/images/bg-qcr.png") no-repeat 50% 50%/6.7rem auto;}
.num-btn-list li .item .button-number { width: 6.7rem; height: 3.2rem; padding:0; }
.num-btn-list li .item.on,
.num-btn-list li .item:active {background: url("../../../public/images/bg-qcr-on.png") no-repeat 50% 50%/6.7rem auto;}

/*P-SIS 검사결과 입력> Q-Point 사진*/
.comm-slide-wrap.img-type {padding: 0; margin-top: -2rem; background: #E9E9E9;}
.comm-slide-wrap.img-type .swiper { background: #E9E9E9; padding-bottom: .5rem;}
.comm-slide-wrap.img-type .swiper-slide.img-type {padding: 1.7rem 2rem; }
.comm-slide-wrap.img-type .swiper-slide.img-type img {width: 100%;}
.result-img-box .h2-title {height: 5.6rem; display: flex; align-items: center; justify-content: center; border-top: 1px dotted #ccc; background-color: #fff;}
.result-img-box .img-wrap {padding: 1.7rem 2rem; background: #E9E9E9;}
.result-img-box .img-wrap img {width: 100%;}

.result-box .table-wrap{border-top: 1px solid #B8B8B8; border-bottom: 1px solid #B8B8B8; margin-top: .5rem;}
.result-box .table-wrap table th {background-color: #fff;}
.result-box .table-wrap table tr:last-child td {border-bottom: 0;}


@media all and (max-width: 379px) {
  .container.login .login-conts {margin-top: 3rem;}
  .container.login .heading-group {margin-bottom: 4rem;}
}


@media all and (min-width: 550px) {

  .container.main .main-conts,
  .contents-table-wrap,
  .table-wrap,
  .card-wrap {overflow-y: initial;}

  /*scroll-wrap*/
  .comm-slide-wrap,
  .scroll-wrap,
  .setting-list-wrap {overflow-y: initial; height: auto; }

  .card-wrap {
    height: auto;
  }
}




/*개발자 추가, 수정, 삭제 CSS 이력*/
/*20230831 추가*/
.container-noheader {position: relative;display: flex; flex-direction: column; width: 100%; padding-top: 1.5rem; height: calc(100% - 5.7rem) !important; overflow-y: scroll;}		/*헤더가 없은 component에 사용*/
.table-wrap table td > a {width: 100%;display: inline-block;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}		/*page7.html 페이지 유형에 사용*/
.ellipsis-text {width: 100% !important;display: inline-block !important;text-overflow: ellipsis !important;white-space: nowrap !important;overflow: hidden !important;}		/*page3.html 페이지 유형에 사용(일괄적용 불가 사유 : page4.html, page22.html 페이지 유형에서 사용 불가)*/
.ellipsis-text-nowidth {display: inline-block !important;text-overflow: ellipsis !important;white-space: nowrap !important;overflow: hidden !important;} /*page3.html 페이지 유형에 사용(일괄적용 불가 사유 : page4.html, page22.html 페이지 유형에서 사용 불가)*/
.transitions-wrapper-noheader {display: flex; flex-direction: column;height: calc(var(--vh, 1vh) * 100)}

/*20230831 수정 : 말줄임 추가*/
.card-list .card-item .date-list li em {width: 50%;display: inline-block; text-overflow: ellipsis; white-space: nowrap;overflow: hidden;}
.card-list .card-item .date-list li span {width: 50%;display: inline-block; text-overflow: ellipsis; white-space: nowrap;overflow: hidden;text-align: right}
.card-list .card-item section .title-wrap span {font-size: 1.6rem; margin-bottom: .2rem; width: 100%; display: inline-block;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
.card-list .card-item section .title-wrap strong {font-size: 2.4rem; font-weight: 700; line-height: 120%;width: 100%; display: inline-block;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
.card-list .card-item section .title-wrap a {display: block; display: inline-block;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}

/*20230901 추가*/
.file-search-wrap {position: relative; width: 100%; margin-right: .5rem;}
.file-search-wrap input {position: absolute; left: 0; top: 0; width: 100%; height: 3.6rem; opacity: 0; cursor: pointer;}
.file-search-wrap .file-search {display: flex; align-items: center; justify-content: center; height: 4.9rem; width: 100%; padding: 0 2rem; background-color: #fff;border: 1px solid #3478F5; color: #3478F5; white-space: nowrap; border-radius: 3.6rem; font-size: 1.8rem; font-weight: 700;}

/*20230901 수정 : 검색결과 메인창. 텍스트가 길 경우, 화면깨짐. 최소높이로 설정 후 긴 텍스트는 자동 줄바꿈 될 수 있도록 수정*/
.reports-list li {display: flex; min-height:5rem; margin-bottom: 2.5rem;}

.comm-slide-wrap .swiper-pagination-bullet {border: 0.4rem solid #ccc; background-color: #fff; opacity: 1; font-size: 0; margin: 0 0.2rem !important;}
.comm-slide-wrap .swiper-pagination {position: relative; justify-content: center}
.comm-slide-wrap .swiper-pagination-bullet-active-main {border: 0.4rem solid #3478F5}

/*20230906 추가 : 판정결과 select box*/
.judgment-select-box {background-image: url("../../../public/images/iocn-arrow-down2.png");background-position: right 1.6rem top 50%;background-size: 2.2rem auto;background-repeat: no-repeat;border: none;border-radius: unset;height: 5.5rem;color: #FFFFFF;font-size: 2.4rem;font-weight: 700;text-align: center;margin-bottom: 1.5rem;}
.judgment-select-box option {background-color: #FFFFFF;color: #000000;}
.judgment-select-box:focus {outline: 0;}
.judgment-select-box:disabled {opacity: unset !important;background-image: unset !important;}

/*20230908 수정 : dialog 버튼 스타일. 해당 스타일이 검색 등 레이어 컴퍼넌트 버튼에 영향을 주므로 dialog에 인라인으로 설정 후 주석처리*/
/*.pop-layer [class*="button-"] {height: 3.6rem;  font-size: 1.6rem; }*/

/*20230919 수정 : 사파리 브라우저 상단 공백 조정*/
header {flex-shrink: 0; z-index: 3; position: sticky; left:0; width: 100%; height: 5.7rem;border-bottom: 1px dotted #ccc; background-color: #fff;}

/*20230919 추가(유길용)*/
input[type="number"] {height: 4rem; width:100%; text-align: right;padding-right: 1.4rem; font-size: 1.6rem; border-radius: .2rem; border: 1px solid #999; background: #FFF; font-family:'Noto Sans KR','AppleSDGothicNeo','roboto',Sans-Serif;}
.btn-ocr {width: 2rem; height: 3rem; margin-right: 1rem; background: url("../../../public/images/ocr.png") no-repeat 0 50%/1.8rem auto;}

/*20230920 추가(노은명)*/
/*.item-wrap .ellipsis1 {text-overflow: ellipsis; overflow: hidden; display: -webkit-box;-webkit-box-orient: vertical; -webkit-line-clamp: 1;}*/
/*.item-wrap .ellipsis2 {text-overflow: ellipsis; overflow: hidden; display: -webkit-box;-webkit-box-orient: vertical; -webkit-line-clamp: 2;}*/
.swiper-slide .contents-table-list>li .title {width: 7rem!important;min-width: 6rem!important;max-width: 7rem!important;text-align: center!important;}
.contents-table-list>li .item-wrap {min-width: initial!important;}
.pop-layer .wrap header + div {height: 100%;display: flex;flex-direction: column;}
.swiper-button-prev,.swiper-button-next {display: none !important;}
@media (max-height:900px) {
  .transitions-wrapper .container.main .main-conts {flex: initial;overflow: initial;}
}

/*20230925 추가 : input type date*/
input[type="date"] {height: 4rem;padding-left: 1.4rem;font-size: 1.6rem;border-radius: .2rem;border: 1px solid #999;background: #FFF;font-family: 'Noto Sans KR', 'AppleSDGothicNeo', 'roboto', Sans-Serif;position: relative;background-image: url("../../../public/images/icon-calendar.png");background-repeat: no-repeat;background-size: 3.8rem;background-position: right center;}
input[type="date"]::-webkit-calendar-picker-indicator {position: absolute;left: 0;top: 0;width: 100%;height: 100%;background: transparent;color: transparent;cursor: pointer;}

/*20231004 수정 : 20230920버전 추가 후 수정. 인피니티스크롤 안되는 현상발생하여 수정됨*/
.card-wrap {height: 100%; padding: 2.5rem 2rem 0; margin-top: -2rem; background: #E9E9E9; overflow-y: auto;}

/*20231004 수정(노은명)*/
header + .transitions-wrapper {height: calc(var(--vh, 1vh)*92)!important;}
.container .footer p {padding: 0 1rem;}
.menu-wrap article .menu-list button {justify-content: flex-start;}
.card-list .card-item section .title-wrap a {width: 100%;}
.swiper-slide .result-box {padding: 0 0;}
.swiper-slide .photo-wrap .h2-title {padding: 0 0;}
.dashed-box .result-file .file-name .write {font-size: 1.6rem; height: 3.4rem;}


/*20231005 수정 : n줄 줄바꿈 css 적용 문법 오류. 20230920 추가스타일에서 수정됨*/
.item-wrap.ellipsis1 {text-overflow: ellipsis; overflow: hidden; display: -webkit-box;-webkit-box-orient: vertical; -webkit-line-clamp: 1;}
.item-wrap.ellipsis2 {text-overflow: ellipsis; overflow: hidden; display: -webkit-box;-webkit-box-orient: vertical; -webkit-line-clamp: 2;}


/*20231008 디자인 수정사항 반영*/
.btn-home-m {width: 4rem; height: 4rem; border-radius: 1.5rem; background:#3478F5 url("../../../public/images/icon-home.png") no-repeat 50% 50%/2.4rem auto;}
.container .footer p {font-size: 1rem;text-align: center;}
.all-menu-wrap .all-menu-top {align-items: center; padding: 0 1rem 0 .5rem;}
.all-menu-wrap .all-menu-top  > div > button {height: 3rem; width: 3rem; padding: 0;}
.all-menu-wrap .all-menu-top .left {display: flex; align-items: center; padding: 0;}
.all-menu-wrap .all-menu-top .left button {margin-right: .5rem; }
.all-menu-wrap .all-menu-top .left .name {display: flex; flex-direction: column;}
.all-menu-wrap .all-menu-top .left .name strong {font-weight: 700; font-size: 1.6rem; line-height:130%;}
.all-menu-wrap .all-menu-top .left .name em { line-height:130%; font-size: 1.4rem;}
.all-menu-wrap .all-menu-top .btn-home,
.all-menu-wrap .all-menu-top .btn-logout,
.all-menu-wrap .all-menu-top .btn-setting,
.all-menu-wrap .all-menu-top .btn-close {background-position: 50% 50%; background-size: 2.4rem auto;}
.reports-list li.form-item.type-date {align-items: flex-start;}
.reports-list li.form-item.type-date .title {margin-top: .2rem;}
.reports-list li.form-item .type-date-in {display: flex; flex-direction: column;}
.reports-list li.form-item .type-date-in .radio-item {display: flex; justify-content: flex-start; align-items: self-start; }
.reports-list li.form-item .type-date-in .radio-item label input {width:2.4rem;}
.reports-list li.form-item .type-date-in .datepicker-wrap {margin-top: .8rem;}
.search-wrap .icon-btn-search {background-color: #E1E1E1;}
.search-wrap .icon-btn-search:active {background-color:#D1D1D1}

/*20231012 수정 : pull to refresh(iOS 적용 안됨)*/
body {height: 100%;font-size: 1.2rem;font-family: 'Noto Sans KR', 'AppleSDGothicNeo', 'roboto', Sans-Serif;font-weight: 400;color: #000;background-color: #Ffff;overscroll-behavior: contain;touch-action: pan-y;}

/*20231019 추가 : 부품검색 조건 select box*/
select.search-select {width: 100%;height: 4rem;padding-left: 0.5rem;border: 1px solid #999;border-radius: .3rem;font-size: 1.5rem;color: #6F6F6F;background: #fff url("../../../public/images/select_arrow-up2.png") no-repeat right 0.1rem top 50%/2rem auto;-webkit-appearance: none;-moz-appearance: none;appearance: none;font-family: 'Noto Sans KR', 'AppleSDGothicNeo', 'roboto', Sans-Serif;}

/*20231025 추가 : 부품 바코드스캔 검색 버튼*/
button.qms-btn-barcode {width: 2rem;height: 3rem;background: url("../../../public/images/btn-barcode.png") no-repeat 0 50%/2rem auto;position: absolute;top: 0.5rem;margin-left: 0.5rem;}

/*항상 하단에 위치*/
@media all and (max-width: 280px) {
  header h1 {font-size: 1.6rem;}
  .all-menu-wrap .menu-wrap article {margin-left: 2.2rem;}
  .menu-wrap article {padding: 2rem;}
  .menu-wrap article .menu-list { margin: 0 0;}
  .menu-wrap article .menu-list button i { width: 7.5rem;}
}

/* 23.11.06 swiper navigation 관련 css */
.swiper-next, .swiper-prev{
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0px;
}
.swiper-next, .swiper-next-nopage{
  background-image: url("../../../public/images/icon-arrow-right.png");
  right: 10px;
}
.swiper-prev, .swiper-prev-nopage{
  background-image: url("../../../public/images/icon-arrow-left.png");
  left: 10px;
}
.swiper-prev.swiper-button-disabled, .swiper-next.swiper-button-disabled, .swiper-prev-nopage.swiper-button-disabled, .swiper-next-nopage.swiper-button-disabled{
  filter: opacity(0.5)
}
.swiper-next-nopage, .swiper-prev-nopage{
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 25px;
  height: 25px;
  top: -0.5rem;
}
.terms-content span {display: inline;}/*2024.11.08 zilong.ge 서비스 이용약관 및 글로벌향 p.p 추가 반영 요청-mobile css*/