.transitions-wrapper {
    position: relative;
    /*overflow: hidden;*/
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
}
.right-enter {
    /* z-index: 0; */
    transform: translateX(100%);
}
.right-enter-active {
    /* z-index: 1; */
    transform: translateX(0);
    transition: transform 400ms ease-in-out;
    /* transition-timing-function: ease-in-out; */
}
.right-exit {
    /* z-index: 1; */
    transform: translateX(0);
}
.right-exit-active {
    /* z-index: 0; */
    transform: translateX(-100%);
    transition: transform 400ms ease-in-out;
    /* transition-timing-function: ease-in-out; */
}
.left-enter {
    /* z-index: 0; */
    transform: translateX(-100%);
}
.left-enter-active {
    /* z-index: 1; */
    transform: translateX(0);
    transition: transform 400ms ease-in-out;
    /* transition-timing-function: ease-in-out; */
}
.left-exit {
    /* z-index: 1; */
    transform: translateX(0);
}
.left-exit-active {
    /* z-index: 0; */
    transform: translateX(100%);
    transition: transform 400ms ease-in-out;
    /* transition-timing-function: ease-in-out; */
}
.bottom-enter {
    /* z-index: 0; */
    transform: translateY(100%);
}
.bottom-enter-active {
    /* z-index: 1; */
    transform: translateY(0);
    transition: transform 400ms ease-in-out;
    /* transition-timing-function: ease-in-out; */
}
.bottom-exit {
    /* z-index: 1; */
    transform: translateY(0);
}
.bottom-exit-active {
    /* z-index: 0; */
    transform: translateY(-100%);
    transition: transform 400ms ease-in-out;
    /* transition-timing-function: ease-in-out; */
}
.top-enter {
    /* z-index: 0; */
    transform: translateY(-100%);
}
.top-enter-active {
    /* z-index: 1; */
    transform: translateY(0);
    transition: transform 400ms ease-in-out;
    /* transition-timing-function: ease-in-out; */
}
.top-exit {
    /* z-index: 1; */
    transform: translateY(0);
}
.top-exit-active {
    /* z-index: 0; */
    transform: translateY(100%);
    transition: transform 400ms ease-in-out;
    /* transition-timing-function: ease-in-out; */
}